<template>
  <v-card-text>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="nonVerifiedLeaders"
      fixed-header
      height="450px"
      :loading="tableLoading"
      loading-text="Loading... Please wait"
      dense
      show-select
      :single-select="true"
      :search="search"
      item-key="index"
    >
      <template v-slot:top>
        <v-toolbar flat dense v-if="selected.length > 0">
          <v-toolbar-title
            >Please verify the leader before continuing to submit the data...
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="selected = []"> Dismiss </v-btn>
          <v-btn text color="success" @click="postData('Verify')">
            Verify
          </v-btn>
          <v-btn text color="error" @click="postData('Reject')"> Reject </v-btn>
        </v-toolbar>
        <v-toolbar flat dense>
          <v-toolbar-title>Verify Leaders</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-chip class="ma-2" color="green" text-color="white">
            Total
            <v-avatar right class="green darken-4">
              {{ nonVerifiedLeaders.length }}
            </v-avatar>
          </v-chip>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.change_type="{ item }">
        {{ item.change_request.change_type }}
      </template>
      <template v-slot:item.changes="{ item }">
        {{ item.change_request.changes }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.change_request.status }}
      </template>
      <template v-slot:item.first_name="{ item }">
        {{ item.basic_details.first_name }}
      </template>
      <template v-slot:item.age="{ item }">
        {{ item.basic_details.age }}
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.basic_details.email }}
      </template>
      <template v-slot:item.gender="{ item }">
        {{ item.basic_details.gender }}
      </template>
      <template v-slot:item.village="{ item }">
        {{ item.basic_details?.location_info?.village }}
      </template>
      <template v-slot:item.mandal="{ item }">
        {{ item.basic_details?.location_info?.mandal }}
      </template>
      <template v-slot:item.ward="{ item }">
        {{ item.basic_details?.location_info?.ward }}
      </template>
      <template v-slot:item.district="{ item }">
        {{ item.basic_details?.location_info?.district }}
      </template>
      <template v-slot:item.state="{ item }">
        {{ item.basic_details?.location_info?.state }}
      </template>
      <template v-slot:item.mla_constituency="{ item }">
        {{ item.basic_details?.location_info?.mla_constituency }}
      </template>
      <template v-slot:item.mp_constituency="{ item }">
        {{ item.basic_details?.location_info?.mp_constituency }}
      </template>
      <template v-slot:item.image="{ item }">
        <v-icon @click="showImage(item.basic_details.image)" color="black"
          >mdi-image-area</v-icon
        >
        
      </template>
      <template v-slot:item.changes="{ item }">
        <div v-if="item.change_request?.changes && item.change_request?.changes.includes('https:')">
          <v-icon @click="showImage(item.change_request.changes)" color="black"
          >mdi-image-area</v-icon
        >
        <!-- <v-dialog v-model="showChangeImageDialog" max-width="500px">
          <v-img
            :src="item.change_request.changes"
            alt="Image"
            max-width="500"
            max-height="500"
          ></v-img>
        </v-dialog> -->
        </div>
        <div v-else>{{ item.change_request?.changes }}</div>
        <!-- <v-icon @click="showImageDialog = true" color="black"
          >mdi-image-area</v-icon
        
        >
        
        <v-dialog v-model="showImageDialog" max-width="500px">
          <v-img
            :src="item.change_request.changes"
            alt="Image"
            max-width="500"
            max-height="500"
          ></v-img>
        </v-dialog> -->
      </template>
    </v-data-table>
    <v-dialog v-model="showImageDialog" max-width="500px">
          <v-img
            :src="currentImage"
            alt="Image"
            max-width="500"
            max-height="500"
          ></v-img>
    </v-dialog>
  </v-card-text>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
// import { HollowDotsSpinner } from "epic-spinners";

export default {
  name: "Home",
  components: {
    // HollowDotsSpinner,
  },
  data: () => ({
    selected: [],
    v0: true,
    moment: moment,
    search: "",
    tableLoading: false,
    showImageDialog: false,
    showChangeImageDialog: false,
    currentImage:'',
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
      },
      { text: "Change", value: "changes" },
      {
        text: "Change Type",
        value: "change_type",
      },
      {
        text: "Name",
        value: "first_name",
      },
      {
        text: "Age",
        value: "age",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Gender",
        value: "gender",
      },
      {
        text: "Ward",
        value: "ward",
      },
      {
        text: "Village",
        value: "village",
      },
      {
        text: "Mandal",
        value: "mandal",
      },
      {
        text: "District",
        value: "district",
      },
      {
        text: "State",
        value: "state",
      },
      {
        text: "MLA constituency",
        value: "mla_constituency",
      },
      {
        text: "MP constituency",
        value: "mp_constituency",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Image",
        value: "image",
      },
      // {
      //   text: "Last Name",
      //   value: "last_name",
      // },
      // {
      //   text: "Alias",
      //   value: "alias",
      // },
      // {
      //   text: "Age",
      //   value: "age",
      // },
      // {
      //   text: "Gender",
      //   value: "gender",
      // },
      // {
      //   text: "Email",
      //   value: "email",
      // },
      // {
      //   text: "Phone No",
      //   value: "phone_no",
      // },
      // {
      //   text: "State",
      //   value: "state_name",
      // },
      // {
      //   text: "District",
      //   value: "district_name",
      // },
      // {
      //   text: "Mandal",
      //   value: "mandal_name",
      // },
      // {
      //   text: "Village",
      //   value: "village_name",
      // },
      // {
      //   text: "Ward",
      //   value: "ward_name",
      // },
      // {
      //   text: "MP C",
      //   value: "mpconstituency_name",
      // },
      // {
      //   text: "MLA C",
      //   value: "mlaconstituency_name",
      // },
    ],
  }),
  computed: {
    ...mapGetters(["nonVerifiedLeaders"]),
    ...mapState(["response"]),
  },
  mounted() {
    this.$store.dispatch("getNonVerifedLeaders");
  },
  methods: {
    ...mapActions(["verifyLeaders"]),
    async postData(from) {
      if (this.selected.length > 0) {
        // let leaders = this.selected.map((items) => items.leader_id);
        // console.log(leaders);
        const payload = {
          cr_id: this.selected[0].change_request.id,
          action: from == "Verify" ? "Approved" : "Rejected",
          // approved_by: this.$store.state?.user?.admin_uuid,
          approved_by: "7feb952f-b294-4466-808e-4cc01dede231",
        };
        await this.verifyLeaders(payload);
        await this.loadNonVerifiedLeaders();
      }
    },
    showImage(imgSrc){
      this.currentImage="";
      this.currentImage=imgSrc;
      this.showImageDialog=true;
    }
  },
};
</script>
<style scoped>
.custom-badge {
  background-color: darksalmon;
}
</style>
